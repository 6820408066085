import React, { useRef, useEffect } from "react"
import Link from "gatsby-link"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Image from "gatsby-image"
import Kontakt from "../components/kontakt"
import { graphql } from "gatsby"
import Footer from "../components/footer"
import SEO from "../components/seo"
import scrollToElement from "scroll-to-element"
import HeadImage from "../images/website.png"
import ogImageBlog from "../images/og-image/og-image-blog.png"
import gsap, {
  CSSPlugin,
  TimelineMax,
  TweenMax,
  Power2,
  Power4,
  slow,
} from "gsap"
import AnimatedHeadline from "../components/AnimatedHeadline"

export default function PostsTemplate(props) {
  const data = props.data
  const [, ...posts] = data.allWordpressPost.edges
  const firstPost = data.allWordpressPost.edges[0].node
  console.log(data)
  const rest = [...posts]

  let mobile =
    typeof window !== "undefined" && window.innerWidth < 1024 ? false : true
  let desktop =
    typeof window !== "undefined" && window.innerWidth >= 1024 ? false : true

  function formatDate(date) {
    let day = date.getDay()
    let month = date.getMonth()
    let year = date.getFullYear()

    const options = { year: "numeric", month: "long", day: "numeric" }

    /*   console.log(date.toLocaleDateString('de-DE', options)); */
    return date.toLocaleDateString("de-DE", options)
  }

  return (
    <>
      <SEO
        title="Blog. Was uns bei Cuuno fasziniert."
        description="Was uns bei Cuuno fasziniert. Bleiben Sie auf dem Laufenden. Lesen Sie über Technologien und sonstige Neuigkeiten."
        image={ogImageBlog}
        defaultTitle="Blog - Cuuno"
        titleTemplate="Blog"
      />
      <div className="cb-container">
        <div className="cb-herosection">
          <div class="cb-herosection-wrap">
            <AnimatedHeadline
              scrollTo={"#post"}
              offset={-150}
              mobileText="Bleiben Sie auf dem Laufenden. Lesen Sie über Technologien und sonstige Neuigkeiten."
              desktopText={[
                "Bleiben Sie auf dem Laufenden.",
                "Lesen Sie über Technologien",
                "und sonstige Neuigkeiten.",
              ]}
            />
          </div>
        </div>
      </div>
      <div id="post" class="cb-blog">
        <div class="cb-container">
          <div class="cb-blog-header">
            <div class="cb-blog-header-grid">
              <div class="cb-blog-header-grid-img">
                <Link
                  to={`/blog/${firstPost.slug}`}
                  class="cb-blog-header-grid-col -left"
                >
                  {firstPost.featured_media && (
                    <Image
                      fadeIn={true}
                      fluid={{
                        ...firstPost.featured_media.localFile.childImageSharp
                          .fluid,
                        aspectRatio: 16 / 9,
                      }}
                      alt={firstPost.title}
                    />
                  )}
                </Link>
                <div class="cb-blog-header-grid-col -right">
                  <div class="cb-blog-heading">
                    <h1>{firstPost.title}</h1>
                  </div>

                  <div class="cb-blog-subheading">
                    <h1>
                      <span>{formatDate(new Date(firstPost.date))} -</span>{" "}
                      <span>{firstPost.categories[0].name}</span>
                    </h1>
                  </div>
                </div>
              </div>

              <div class="cb-blog-header-mobile">
                <div class="cb-blog-heading">
                  <h1>{firstPost.title}</h1>
                </div>
              </div>
            </div>
          </div>

          <div class="cb-blog-content">
            {rest.map(({ node: post }) => {
              console.log(post)
              return (
                <div key={post.id} className="cb-blog-content-item">
                  <Link to={`/blog/${post.slug}`} key={post.id}>
                    <Image
                      fadeIn={true}
                      fluid={{
                        ...post.featured_media.localFile.childImageSharp.fluid,
                        aspectRatio: 4 / 3,
                      }}
                      alt={post.title}
                    />

                    <div class="cb-blog-content-item-body">
                      <div class="cb-blog-content-item-subheading">
                        <h1>
                          <span>{formatDate(new Date(post.date))} -</span>{" "}
                          <span>{post.categories[0].name}</span>
                        </h1>
                      </div>
                    </div>
                  </Link>
                  <div class="cb-blog-content-item-heading">
                    <h1>{post.title}</h1>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Kontakt />
      <Footer />
    </>
  )
}

PostsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const pageQuery = graphql`
  query postsQuery {
    allWordpressPost {
      edges {
        node {
          id
          title
          excerpt
          slug
          categories {
            name
          }

          acf {
            ersteZeile
            zweiteZeile
            dritteZeile
          }
          date(formatString: "MMMM DD, YYYY")
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  aspectRatio
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
